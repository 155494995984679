.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* area:-webkit-any-link {
  cursor: pointer;
}

map:hover {
  background-color: red !important;
} */

/* ImageMap Start */
.image-map__content span {
  cursor: pointer;
}

.image-map__content span:hover {
  /* object-fit: cover !important; */
  /* object-position: bottom right !important; */
  /* background-color: red !important; */
  /* border: 1px solid white; */
  /* background: rgb(232, 219, 225); */
  border-radius: 15px;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.15) 9%, rgba(54, 54, 54, 0.15) 72%, rgba(54, 54, 54, 0) 100%);
}

.image-map__content {
  display: flex !important;
  flex: 1 !important;
  justify-items: center !important;
}

.image-map__content img {
  width: 100%;
  -webkit-user-select: none;
  user-select: none;
  pointer-events: none;
}

.custom-image-map-view {
  position: relative;
  /* margin: auto; */
}

.custom-image-map-view video {
  display: block !important;
  width: 100% !important;
  height: auto !important;
  position: absolute;
  pointer-events: none;
}

.custom-image-map-view-hidden img {
  opacity: 0 !important;
  /* position: absolute; */
}

.custom-image-map-view .live-player {
  width: 100%;
  position: fixed;
  // height: 100%;
  // min-height: calc(100vh - 64px);
  min-height: calc(100vh - 48px);
  // float: left;
  // top: 64px;
  top: 48px;
}

.custom-image-map-view .live-player-2 {
  width: 100% !important;
  height: 100% !important;
  max-height: calc(100vh - 48px) !important;
  // top: 48px;
  // height: calc(100vh) !important;
  // min-height: calc(100vh - 64px);
}

.custom-image-map-view iframe {
  width: 100% !important;
  height: 100% !important;
  max-height: calc(100vh - 48px) !important;
  // top: 48px;
  // height: calc(100vh) !important;
  // min-height: calc(100vh - 64px);
}

.custom-image-map-view .live-player-3 {
  width: 100% !important;
  height: 20vh !important;
  max-height: calc(100vh - 48px) !important;
  // background-color: red !important;
  // top: 48px;
  // height: calc(100vh) !important;
  // min-height: calc(100vh - 64px);
}
/* ImageMap End */

/* Scrollbar Start */
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
/* Scrollbar End */

.eh-dialog-container {
  /* background-color: red; */
  // float: left;
  /* overflow: hidden; */
  /* max-height: 70vh; */
  width: 100%;
}
// .eh-dialog-container img {
//   display: block;
// }

.portrait img {
  // width: 100%;
}
.landscape img {
  // height: 100%;
}

.eh-dialog-container img,
object,
iframe {
  display: block;
}

.keep-it {
  pointer-events: none !important;
  user-select: none !important;
  -moz-user-select: none !important;
  -webkit-user-drag: none !important;
  -webkit-user-select: none !important;
  -ms-user-select: none !important;
}

.image-gallery {
  object-fit: contain !important;
  object-position: center !important;
}

.image-gallery img {
  // max-height: 70vh;
  object-fit: contain !important;
  object-position: center !important;
}

.image-gallery-swipe {
  // max-height: 70vh !important;
  // height: 70vh !important;
  // object-fit: fill !important;
}

.image-gallery-slides img {
  width: 100% !important;
  overflow-y: scroll;
}

.image-gallery-thumbnail-image {
  min-height: 64px !important;
  max-height: 64px !important;
}

.capture-button {
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
  bottom: 20px !important;
  z-index: 20;
}

.custom-column {
  display: flex !important;
  flex-direction: column !important;
}

.custom-row2 {
  display: flex !important;
  flex-direction: row !important;
  width: 100% !important;
}

.custom-confetti {
  z-index: 9999 !important;
  max-width: 99.5vw !important;
}

.custom-dialog {
  z-index: 1400 !important;
}

.custom-dialog-others {
  z-index: 99999 !important;
}

.custom-shadow {
  // -webkit-box-shadow: -5px 7px 5px 0px rgba(0, 0, 0, 0.32);
  // -moz-box-shadow: -5px 7px 5px 0px rgba(0, 0, 0, 0.32);
  // box-shadow: -5px 7px 5px 0px rgba(0, 0, 0, 0.32);
  // background-color: rgba(0, 0, 0, 0.32);
  // border-radius: 50%;
}

.chat-history {
}

.videoInsert {
  width: "100%";
  height: "100%";
  border-radius: 10px;
  object-fit: cover !important;
}

.participantWrapper {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  height: 0;
}

.participantWrapper-mobile {
  position: relative;
}

.participantVideo {
  // width: 100% !important;
  // // height: auto !important;
  // border-radius: 10px;
  // object-fit: cover !important;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 5px;
  object-fit: cover !important;
  background-color: #282c34;
}

.participantVideo-mobile {
  // width: 100% !important;
  // // height: auto !important;
  // border-radius: 10px;
  // object-fit: cover !important;
  width: 100%;
  height: 100%;
  border-radius: 5px;
  object-fit: cover !important;
  background-color: #282c34 !important;
}

.facilitatorVideo {
  position: absolute;
  top: 2%;
  right: 2%;
  width: 100%;
  height: 100%;
}

.facilitatorVideo-mobile {
  position: absolute;
  top: 2%;
  right: 2%;
  width: 100%;
  height: 100%;
}

.fadeOut {
  opacity: 0;
  width: 0;
  height: 0;
  background: transparent !important;
  background-color: transparent !important;
  transition: width 0.5s 0.5s, height 0.5s 0.5s, opacity 1s;
}
.fadeIn {
  opacity: 1;
  width: 100px;
  height: 100px;
  transition: width 0.5s, height 0.5s, opacity 0.5s 0.5s;
}

.video-on-screen-overlay:hover {
  /* object-fit: cover !important; */
  /* object-position: bottom right !important; */
  /* background-color: red !important; */
  /* border: 1px solid white; */
  /* background: rgb(232, 219, 225); */
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.15) 9%, rgba(54, 54, 54, 0.15) 72%, rgba(54, 54, 54, 0) 100%);
}

.video-on-screen-container video {
  /* Make video to at least 100% wide and tall */
  // min-width: 100%;
  // min-height: 100%;

  /* Setting width & height to auto prevents the browser from stretching or squishing the video */
  width: auto;
  height: 100% !important;

  /* Center the video */
  // position: absolute;
  // top: 50%;
  // left: 50%;
  // transform: translate(-50%, -50%);
}

.custom-parser-container {
  display: flex !important;
  flex: 1 !important;
}

.custom-parser {
  width: 100% !important;
  height: 100% !important;
  align-items: flex-start !important;
  justify-content: center !important;
  // align-self: center !important;
  display: flex !important;
  // flex: 1 !important;
  // position: absolute !important;
  // top: 50% !important;
  // left: 50% !important;
  // transform: translate(-50%, -50%) !important;
}

.custom-parser2 {
  width: 100% !important;
  height: 100% !important;
  align-items: flex-start !important;
  justify-content: center !important;
  // align-self: center !important;
  display: flex !important;
  flex: 1 !important;
  // position: absolute !important;
  // top: 50% !important;
  // left: 50% !important;
  // transform: translate(-50%, -50%) !important;
}

.custom-parser2 iframe {
  width: 100% !important;
  height: 100% !important;
}

.iiz {
  min-width: 100% !important;
  display: flex !important;
  flex: 1 !important;
  width: 100% !important;
  align-items: center !important;
  justify-content: center !important;
}

// ckeditor
.ck-editor__editable {
  min-height: 120px !important;
}

.broadcast-message {
  white-space: pre-wrap !important;
}

.ratioWrapper {
  position: relative;
  // padding-bottom: 56.25%; /* 16:9 */
  padding-bottom: 100%; /* 16:9 */
  height: 0;
}

.ratioContent {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 5px;
  object-fit: contain !important;
  background-color: #fff;
}

.resizable-text1 {
  // font-size: calc(50px + 2vmin) !important;
  font-size: calc(50px + (26 - 14) * ((100vw - 300px) / (1600 - 300))) !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.resizable-text2 {
  font-size: calc(70px + 2vmin) !important;
}

.custom-text1 {
  word-break: break-word !important;
  font-size: calc(10px + 5vmin) !important;
}

.custom-text2 {
  word-break: break-word !important;
  font-size: calc(10px + 10vmin) !important;
}

.custom-text22 {
  word-break: break-word !important;
  font-size: calc(10px + 15vmin) !important;
}

.custom-text3 {
  word-break: break-word !important;
  word-wrap: break-word !important;
  font-size: calc(40px + 30vmin) !important;
  vertical-align: middle !important;
}

.join-success {
  // word-break: break-word !important;
  word-wrap: break-word !important;
  white-space: pre-line !important;
  font-size: calc(6px + 2vmin) !important;
  // width: 100% !important;
}

.message-popup-title {
  font-size: calc(10px + 3vmin) !important;
}

.message-popup-body {
  font-size: calc(10px + 2vmin) !important;
}

.message-popup-body p:first-child {
  margin: 0px !important;
  padding: 0px !important;
}

.message-popup-button {
  font-weight: 800 !important;
  font-size: calc(10px + 2.5vmin) !important;
}

// Start Raffle
.raffle-winners-wrapper {
  position: absolute;
  top: 100%;
  left: 50%;
  // width: 400px;
  // margin-left: -200px;
  transform: translate(-50%, 0);
  // font: 300 30px/1 "Open Sans Condensed", sans-serif;
  text-align: center;
  text-transform: uppercase;
  color: #fff;
  animation: 15s credits linear infinite;
  overflow: hidden !important;
}

@keyframes credits {
  0% {
    top: 100%;
  }
  100% {
    top: -500%;
  }
}

.custom-text-zz {
  word-break: break-word !important;
  font-size: calc(10px + 4vmin) !important;
}
// End Raffle
